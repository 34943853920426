export function slide(event) {
  event.preventDefault();
  event.stopPropagation();
  const target = event.target.closest('a').getAttribute('href');

  document.querySelector(target).scrollIntoView({
    behavior: 'smooth'
  });

  return false
}
