<template>
  <div>
    <div class="mobile_nav d-md-none">
      <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
            <router-link :to="{name: 'Home'}" class="navbar-brand" v-if="$route.name !== 'Home'">
            <img class="logo" src="img/logo.png" alt="">
            </router-link>
          <a href="body" @click="slide" v-else>
            <img class="logo" src="img/logo.png" alt="">
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link active dropdown-toggle" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-current="page">
                  {{$t('nav.product')}}
                  <svg class="select--icon" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.2605 0.908813L5.86142 3.92655C6.02792 4.03577 6.24336 4.03577 6.40986 3.92656L11.0109 0.908813" stroke="black" stroke-width="2"/>
                  </svg>
                </a>
                <ul class="dropdown-menu px-3" aria-labelledby="dropdownMenuButton2">
                  <li><router-link  @click="hideMenu" :to="{name: 'Products'}"  data-bs-target="#navbarNavDropdown" class="dropdown-item border-bottom">{{$t('nav.exchange')}}</router-link></li>
                  <li><a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="dropdown-item" href="#">С2B</a></li>
                </ul>
              </li>
              <li class="nav-item" >
                <router-link @click="hideMenu"  :to="{name: 'About'}" data-bs-target="#navbarNavDropdown" class="nav-link active" aria-current="page" >{{$t('nav.about')}}</router-link>
              </li>
              <li class="nav-item">
                <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="nav-link active" aria-current="page" href="#contacts" @click="slide">{{$t('nav.contacts')}}</a>
              </li>
<!--              <li class="nav-item">-->
<!--                <a data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" class="nav-link active" aria-current="page" href="#">{{$t('nav.signIn')}}</a>-->
<!--              </li>-->
              <li class="nav-item">
                <a  class="nav-link active" aria-current="page" href="https://app.exchanity.com/">{{$t('nav.logIn')}} </a>
              </li>

              <li class="nav-item dropdown d-none">
                <a  class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Dropdown link
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>

    <section class="header d-none d-md-block">
      <header class="container">
        <nav>
          <a href="" class="nav__link nav__link--logo">
            <router-link :to="{name: 'Home'}" class="nav__link__header">
              <img class="logo" src="img/logo.png" alt="">
            </router-link>
            <div class="nav__link__separator separator"></div>
            <div class="nav__link__footer">
              <div class="t-m"> </div>
            </div>
          </a>
          <span href="" class="nav__link focused nav__link--logo">
            <div class="nav__link__header">
              <!--              <div class="t-m">-->
              <!--                {{$t('nav.signIn')}}-->
              <!--              </div>-->
            </div>
            <div class="nav__link__separator separator"></div>
            <div class="nav__link__footer">
              <div class="t-m">

              </div>
            </div>
          </span>
          <a class="nav__link dropdown router__link" :class="{'focused': router ==='Products' }">
            <div class="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <div class="nav__link__header">
                <div class="t-m">  </div>
              </div>
              <div class="nav__link__separator separator"></div>
              <div class="nav__link__footer">
                <div class="t-m">
                  {{$t('nav.product')}}
                  <svg class="select--icon" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.2605 0.908813L5.86142 3.92655C6.02792 4.03577 6.24336 4.03577 6.40986 3.92656L11.0109 0.908813" stroke="black" stroke-width="2"/>
                  </svg>
                </div>
              </div>
            </div>
            <ul class="dropdown-menu px-3" aria-labelledby="dropdownMenuButton1">
              <li><router-link :to="{name: 'Products'}" class="dropdown-item border-bottom" >{{$t('nav.exchange')}}</router-link></li>
              <li><a class="dropdown-item" href="#">С2B</a></li>
            </ul>
          </a>
          <router-link  :to="{name: 'About'}" class="nav__link router__link"  :class="{'focused': router ==='About' }">
            <div class="nav__link__header">
              <div class="t-m">

              </div>
            </div>
            <div class="nav__link__separator separator"></div>
            <div class="nav__link__footer">
              <div class="t-m">
                {{$t('nav.about')}}
              </div>
            </div>
          </router-link>
          <a href="#contacts" @click="slide" class="nav__link">
            <div class="nav__link__header">
              <div class="t-m">

              </div>
            </div>
            <div class="nav__link__separator separator"></div>
            <div class="nav__link__footer">
              <div class="t-m">
                {{$t('nav.contacts')}}
              </div>
            </div>
          </a>

          <a href="https://app.exchanity.com/" class="nav__link focused">
            <div class="nav__link__header">
              <div class="t-m">
                {{$t('nav.logIn')}}
              </div>
            </div>
            <div class="nav__link__separator separator"></div>
            <div class="nav__link__footer">
              <div class="t-m">

              </div>
            </div>
          </a>
        </nav>

      </header>
    </section>
  </div>
</template>

<script>
import {slide} from "@/utills/all";

export default {
  name: 'AppNav',
  props: {
    msg: String
  },
  computed:{
    router(){
      return this.$route.name
    }
  },
  methods: {
    slide,
    hideMenu(){
      document.querySelector('.navbar-toggler').click()
    }
  },
  mounted() {

  }
}
</script>

