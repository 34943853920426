<template>
<div v-if="!isAccept" class="modal_notif">
  <div class="mess">
  <h4 class="fw-bold pb-3 c-p">Dear valued customer!</h4>
    <p>Please note that Prochange is currently onboarding corporate clients only.
      You will be notified as soon as individual clients are able to use our services. <br>
      We apologise for any inconvenience caused and thank you for your understanding.</p>
    <div class="py-4 d-flex justify-content-between align-items-center">
      <div class="checkbox" @click="isCheck=!isCheck">
        <svg v-show="isCheck" viewBox="0 0 512 512">
          <path fill="var(--primary)" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
          </path>
        </svg>
      </div>

      <button class="btn btn-primary" :disabled="!isCheck" @click.prevent="isAccept=true">Accept and Continue</button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "notification",
  data(){
    return{
      isCheck: false,
      isAccept: false
    }
  },
  methods:{
  hide(){
  this.$refs.notif.style.display = 'none';
  }
  }
}
</script>

<style scoped>
.modal_notif{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1024;
  background: rgba(0, 0, 0, 0.8);
}
.modal_notif .mess{
  padding: 30px;
  border-radius: 10px;
  background: #fff;
  width: 450px;
  max-width: 100%;
  font-size: 14px;
}
.modal_notif .checkbox{
  border: 2px solid #000;
  border-radius: 2px;
  flex: 0 0 20px;
  max-width: 20px;
  height: 20px;
  cursor: pointer;
}
.modal_notif .checkbox svg{
  margin-top: -7px;
}
</style>