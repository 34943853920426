<template>
  <app-nav></app-nav>
  <router-view/>
  <section v-show="$route.name!== 'About'">
    <div class="container" id="contacts">
      <div class="row">
        <div class="col-md-4">
          <div class="separator mb-5" disabled></div>
          <div class="contacts__item">
            <div class="icon mb-1">
              <img src="img/icons/c_1.svg" alt="">
            </div>
            <h3 class="mb-2"> {{ $i18n.t('main.support') }}</h3>
            <p class="text-default">
              {{ $i18n.t('main.supportText') }}
<!--              <a href="mailto:support@exchanity.com">support@exchanity.com</a>-->
              <a href="mailto:support@exchanity.com?subject=Support"> {{ $i18n.t('footer.cont') }}</a>
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="separator mb-5" disabled></div>
          <div class="contacts__item">
            <div class="icon mb-1">
              <img src="img/icons/c_2.svg" alt="">
            </div>
            <h3 class="mb-2">{{ $i18n.t('main.jobs') }}</h3>
            <p class="text-default">
              {{ $i18n.t('main.jobsText') }}
<!--              <a href="mailto:hr@exchanity.com">hr@exchanity.com</a>-->
              <a href="mailto:hr@exchanity.com?subject=HR"> {{ $i18n.t('footer.cont2') }}</a>
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="separator mb-5" disabled></div>
          <div class="contacts__item">
            <div class="icon mb-1">
              <img src="img/icons/c_3.svg" alt="">
            </div>
            <h3 class="mb-2">{{ $i18n.t('main.partner') }}</h3>
            <p class="text-default">
              {{ $i18n.t('main.partnerText') }}
<!--              <a href="mailto:partnership@exchanity.com">partnership@exchanity.com</a>-->
              <a href="mailto:partnership@exchanity.com?subject=Partnership"> {{ $i18n.t('footer.cont3') }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="footer">
    <div class="container">
      <footer id="footer">
        <div class="row">
          <div class="col-md-4 order-md-1 order-3">
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="footer__icon">
                <img src="img/icons/f_1.svg" alt="">
              </div>
              <div class="footer__icon2">
                <div class="locales mb-3 d-none">
                  <button @click="setLocale('ru')" class="locale" :class="{'active': $i18n.locale === 'ru'}">Ru</button>
                  <button @click="setLocale('en')" class="locale" :class="{'active': $i18n.locale === 'en'}">Eng</button>
                </div>
<!--                <div>-->
<!--                  <a class="me-1" href=""><img src="img/icons/twitter.svg" alt=""></a>-->
<!--                  <a class="mx-1" href=""><img src="img/icons/m.svg" alt=""></a>-->
<!--                  <a class="mx-1" href=""><img src="img/icons/instagram.svg" alt=""></a>-->
<!--                  <a class="mx-1" href=""><img src="img/icons/in.svg" alt=""></a>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <div class="col-md-4 order-md-2 footer__col">

            <router-link v-if="$route.name!=='Products'" :to="{name: 'Products'}" class="footer__link">{{$i18n.t('footer.products')}}</router-link>
            <a href="body" v-else @click="slide" class="footer__link">{{$i18n.t('footer.products')}}</a>

            <router-link v-if="$route.name!=='About'" :to="{name: 'About'}" class="footer__link">{{$i18n.t('nav.about')}}</router-link>
            <a href="body" v-else @click="slide" class="footer__link">{{$i18n.t('nav.about')}}</a>

            <router-link v-if="$route.name!=='About'" :to="{name: 'About', params: {scroll: 'form'}}" class="footer__link">{{$i18n.t('nav.contacts')}}</router-link>
            <a href="#form" v-else @click="slide" class="footer__link">{{$i18n.t('nav.contacts')}}</a>

            <router-link :to="{name: 'UserAgreement'}" class="footer__link">{{$i18n.t('footer.terms')}}</router-link>
<!--            <a href="" class="footer__link">{{$i18n.t('footer.documents')}}</a>-->
            <router-link :to="{name: 'RefundAndReturn'}" class="footer__link">{{$i18n.t('footer.refund')}}</router-link>
            <router-link :to="{name: 'AMLAndCTF'}" class="footer__link">{{$i18n.t('footer.policy1')}}</router-link>
            <router-link :to="{name: 'GDRPAndPrivacy'}" class="footer__link">{{$i18n.t('footer.policy2')}}</router-link>
          </div>
          <div class="col-md-4 order-md-2 footer__col">
            <p class="footer__text mb-3">
              Prochange UAB under the brand name Exchanity is a virtual currency exchange and depository wallet operator,
              acting according to the laws of the Republic of Lithuania.
            </p>
            <p class="footer__text mb-3">
              Prochange UAB is regulated by the Lithuanian Financial Crime Investigation Services.
            </p>
<!--            <p class="footer__text mb-3">-->
<!--              The Company is committed to conduct business operations-->
<!--              in a transparent and open manner consistent with its regulatory obligations.-->
<!--            </p>-->
            <p class="footer__text mb-5">
<!--              Register code: 305858194,-->
<!--              Legal address: New York State Department-->
<!--              of Financial Services.-->
              Register code: 305858194, Legal address:
              Gedimino pr. 20,
              Vilnius 01103, Lithuania
            </p>
            <div class="footer-pi">
              <span class="footer-p">
              <img src="img/icons/visa.svg" alt="">
            </span>
              <span class="footer-p">
              <img src="img/icons/master.svg" alt="">
            </span>
            </div>


          </div>
        </div>
      </footer>
    </div>
  </section>
  <notification/>
</template>
<style>
  @import "./assets/css/normalize.css";
  @import "./assets/css/bootstrap.css";
  @import "./assets/css/main.css";
</style>
<script>
  import AppNav from "./components/app-nav";
  import {slide} from "@/utills/all";
  import Notification from "@/components/popup.vue";


  export default {
    components: {Notification, AppNav },
    data(){
      return{
        locale: ""
      }
    },
    methods: {
      setLocale(lang){
        this.$i18n.locale = lang;
        localStorage.locale = lang;
      },
      slide
    },
    created() {
      // if (localStorage.locale === 'ru' || localStorage.locale === 'en' ){
      //
      //    this.$i18n.locale  = localStorage.locale
      // }else {
        localStorage.locale = "en";
         this.$i18n.locale = "en";
      // }
    }
  }
</script>
