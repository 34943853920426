export default {
  en: {
    nav: {
      product: "Product",
      about: "About us",
      contacts: "Contacts",
      signIn: "Sign In",
      logIn: "Log In",
      exchange: "Exchange",
    },
    main: {
    s1h1: "Your crypto currency wallet",
    s1h2: "Digital assets exchange, transfers and payments over the world",
    signUp: "Sign up ",
    s1el1: "Access to demand and promising cryptocurrencies",
    s1el2: "Modern security methods: data encryption, two-factor authentication",
    s1el3: "Support 24/7",
    s1el4: "Transparency of transactions, no hidden fees",
    s1el4Link: "(Explore fees)",
    s1el5: "System of bonuses and benefits",
    s1el5Link: "(Explore bonus system)",
    assets: "Digital Assets",
    sellBtn: "BUY/SELL",
    howWork: "How it works?",
    s2el1: "Do the registration",
    s2el2: "Pass identification for your security",
    s2el3: "Top up your account by credit/debit card, via SEPA/SWIFT transfer, or using crypto wallet",
    s2el4: "Make your first crypto purchase",
    system: "Exchanity system",
    s3Funct: "Functionalities",
    s3FunctText1: "Follow digital assets rates, do trading, exchange cryptocurrencies.",
    s3FunctText2: "Use Exchanity wallet services to keep your digital assets in the safe vault.",
    s3Account: "Personal account",
    s3AccountText: "Top up your balance, withdraw funds, make transfers. Top up by card – instant, SEPA transfers within 10 minutes.",
    s3Statistics: "Statistics and statements",
    s3StatisticsText1: "Use widgets with the necessary information. View the history and status of transactions, generate statements for any period.",
    s3StatisticsText2: "Follow your digital assets balances, by time, interest rate.",
    bonuses: "Bonuses & benefits",
    s4el1: "10 USDT for a new Exchanity verified customers",
    s4el2: "Free of charge bank withdrawal for traders, who makes more than 10 trades per month",
    s4el3: "Cryptocurrencies as prize money",
    s4el4: "Free of charge top up by card",
    s4el5: "Best rate trading via Exchanity dealing desk",
    support: "Support",
    supportText: "Our support team is always ready to help. Create requests inside your personal account or ",
    jobs: "Jobs",
    jobsText: "Always happy to see new colleagues. Join the Exchanity team. If you did not find your vacancy on the site, ",
    partner: "Become a partner",
    partnerText: "Do you provide services or business solutions? We will contact you and discuss options for cooperation. ",
    viewAll: "View all",
    },
    howWorks: {
      verification: "ID verification",
      card: "ID card",
      license: "Driving license",
      passport: "Passport",
      address: "Proof of address",
      bill: "Utility bill",
      invoice: "Tax invoice",
      statement: "Bank statement",
      authentication: "Face authentication"
    },
    system: {},
    benefits: {},
    contacts: {},
    footer: {
      terms: "Terms and Conditons",
      products: "Products",
      documents: "Documents",
      refund: "Refund and Return Policy",
      policy1: "AML/CTF Policy",
      policy2: "GDPR and Privacy Policy",
      cont: "write to the email",
      cont2: "write to our HR",
      cont3: "Write to us"
    },
    aboutUs: {
      main: "Exchanity — is an international, fast, and growing project. Our goal is to offer customers a tool for quick and convenient payments and prove that investing in cryptocurrency is a simple and safe process, available to everyone from anywhere in the world. We are confident that cryptocurrency is safe and promising.",
      formH1: "Team experience",
      formText1: "Our team has extensive experience in fintech. We know what customer needs — convenience and security. These two fundamental factors are embedded in our work. Management is looking for new growth opportunities daily, business specialists and the development team are improving and developing the product, and support is always ready to answer your questions.",
      formH2: "Jobs",
      formText2: "We are happy to recruit new people to the team. We welcome both experienced professionals and beginners. Even if you have no experience at all, we are ready to teach. The main thing is your desire to work and grow with us. Please send your CV to",
      formText2end: ", and our recruiters will contact you.",
      formH3: "Contact us",
      formName: "Name",
      formSubject: "Subject",
      formTextarea: "Text",
      formSend: "Send message",
      address: "Address:",
      address2: "Gedimino pr. 20,",
      address3: "Vilnius 01103, Lithuania"
    },
    product: {
      mainH1: "Exchange",
      mainText1: "Our main product is a platform for buying, selling, and exchanging cryptocurrencies",
      mainH2: "Easy to use",
      mainText2: "There is no difference in buying cryptocurrency on Exchanity compared to exchanging EUR to USD in your online bank. On Exchanity the process is absolutely the same and familiar to you",
      offer: "We offer",
      offerH1: "Exchanity Wallet.",
      offerH12: "The best place to keep your crypto",
      offerText1: "Invest. Cryptocurrency is a new investment tool",
      offerH2: "Modern methods of payments",
      offerText2: "Make transactions at your convenience, we work 24/7.",
      offerText22: "Send cryptocurrency at any time of the day. Your partner or friend will receive the transfer in a minute or within 1 hour, much faster than bank payments",
      offerH3: "High safety standards",
      offerText3: "The platform presents trustworthy cryptocurrencies with a capitalization of 50 million euros.",
      offerText32: "We have high identification standards to protect your account. The system creates a 3D face card based on biometric verification",
      fees: "Fees",
      feesText1: "Check out the complete list of fees and find your most profitable method for depositing and withdrawing funds. We always try to offer the best rates and the lowest rates",
      feesText2: "Fees for top up and withdrawal funds and cryptocurrency for individuals.",
      feesText3: "Unique offer to Exchanity clients",
    }
  },
  ru: {
    nav: {
      product: "Продукты",
      about: "О нас",
      contacts: "Контакты",
      signIn: "Войти",
      logIn: "Авторизация",
      exchange: "Exchange",

    },
    main: {
      s1h1: "Ваша криптоплатформа",
      s1h2: "Обмен валюты, переводы и платежи по всему миру",
      signUp: "Начать",
      s1el1: "Доступ ко всем популярным и перспективным криптовалютам",
      s1el2: "Безопасность: шифрование данных, двухфакторная аутентификация",
      s1el3: "Поддержка 24/7",
      s1el4: "Прозрачность сделок, отсутствие скрытых комиссий",
      s1el4Link: "(подробнее о комиссиях)",
      s1el5: "Система бонусов и привилегий",
      s1el5Link: "(подробнее о бонусах)",
      assets: "Валюты",
      sellBtn: "Купить/Продать",
      howWork: "Как это работает?",
      s2el1: "Зарегистрируйтесь",
      s2el2: "Пройдите идентификацию",
      s2el3: "Пополните счет: с банковской карты, криптокошелька, SEPA/SWIFT переводом",
      s2el4: "Совершайте сделки с криптовалютой",
      system: "Платформа",
      s3Funct: "Функциональность",
      s3FunctText1: "Следите за курсами, торгуйте и обменивайте криптовалюту.",
      s3FunctText2: "Используйте криптокошелек Exchanity для хранения цифровых активов.",
      s3Account: "Личный  кабинет",
      s3AccountText: "Пополняйте баланс, выводите средства, совершайте переводы. Зачисление средств на баланс с банковской карты - мгновенно, SEPA переводы – в течении 10 минут.",
      s3Statistics: "Статистика и отчеты",
      s3StatisticsText1: "Пользуйтесь виджетами с необходимой информацией. Просматривайте историю и статус транзакций, формируйте отчеты за любой период. ",
      s3StatisticsText2: "Следите за балансом криптокошелька: по времени, по изменению в процентах.",
      bonuses: "Бонусы и привилегии",
      s4el1: "10 USDT на счет для новых пользователей ",
      s4el2: "Бесплатный вывод средств на банковский счет при совершении более 10 сделок в месяц",
      s4el3: "Денежные призы в криптовалюте",
      s4el4: "Бесплатное пополнение с карты",
      s4el5: "Обмен крипто-валюты по специальному выгодному курсу",
      support: "Поддержка",
      supportText: "Наша служба поддержки всегда готова помочь. Создавайте запросы внутри личного кабинета или ",
      jobs: "Вакансии",
      jobsText: "Будем рады новым коллегам. Присоединяйтесь команде Exchanity. Если не нашли своей вакансии на сайте, ",
      partner: "Стань партнером",
      partnerText: "Вы предоставляете сервисы или услуги? Мы свяжемся с вами и обсудим варианты сотрудничества, ",
      viewAll: "Посмотреть все",

    },
    howWorks: {
      verification: "ID верификация",
      card: "ID-карта",
      license: "Водительское удостоверение",
      passport: "Паспорт",
      address: "Подтверждение адреса",
      bill: "Счёт за коммунальные услуги",
      invoice: "Налоговый счёт",
      statement: "Выписка из банка",
      authentication: "Face ID"
    },
    system: {},
    benefits: {},
    contacts: {},
    footer: {
      terms: "Условия",
      products: "Продукты",
      documents: "Документы",
      refund: "Правила возврата",
      policy1: "ПОД/ФТ",
      policy2: "Политика конфиденциальности",
      cont: "напишите нам",
      cont2: "напишите нашему HR",
      cont3: "напишите нам"
    },
    aboutUs: {
      main: "Exchanity - международный быстрорастущий проект. Мы уверены, что криптовалюта – это безопасно и перспективно. Наша цель - предложить пользователям инструмент для быстрых и удобных расчетов и доказать, что инвестирование в криптовалюту - простой и безопасный процесс, доступный каждому желающему из любой точки мира",
      formH1: "Опыт команды",
      formText1: "Наша команда имеет большой опыт в финтехе. Мы знаем, что необходимо каждому пользователю – удобство и безопасность. Эти два основополагающих фактора заложены в нашу работу. Менеджмент каждый день ищет новые возможности для роста, бизнес-специалисты и команда разработки улучшают и развивают продукт, а поддержка всегда готова ответить на ваши вопросы. ",
      formH2: "Вакансии",
      formText2: "С удовольствием возьмем новых людей в команду. Мы рады и опытным профессионалам, и начинающим специалистам.  Даже если у вас совсем нет опыта, мы готовы научить, главное – ваше желание работать и расти вместе с нами. Присылайте нам свое CV на",
      formText2end: " и наши рекрутеры обязательно свяжутся с вами.",
      formH3: "Форма для связи",
      formName: "Имя",
      formSubject: "Тема письма",
      formTextarea: "Текст",
      formSend: "Отправить сообщение",
      address: "Адрес:",
      address2: "проспект Гедиминаса 20,",
      address3: " Вильнюс 01103, Литва"
    },
    product: {
      mainH1: "Exchange",
      mainText1: "Наш основной продукт – это платформа по покупке, продаже и обмену криптовалюты",
      mainH2: "Это просто",
      mainText2: "Покупка криптовалюты ничем не отличается от покупки валюты в интернет-банке. Совершать денежные операции на платформе Exchanity будет так же удобно и просто.",
      offer: "Мы предлагаем",
      offerH1: "Exchanity Wallet",
      offerH12: "кошелек для хранения криптовалюты",
      offerText1: "Инвестируйте. Криптовалюта — новый инвестиционный инструмент",
      offerH2: "Современные способы расчетов и инвестирования",
      offerText2: "Совершайте сделки, когда вам удобно, мы работаем 24/7",
      offerText22: "Отправляйте криптовалюту в любое время суток, получатель получит перевод на свой кошелек от 1 минуты до 1 часа, что значительно быстрее банковских платежей.",
      offerH3: "Высокие стандарты безопасности",
      offerText3: "На платформе представлены, заслуживающие доверия, криптовлюты с капитализацией от 50 млн. евро",
      offerText32: "Надежная идентификация для защиты вашего аккаунта. Система создает 3D-карту лица на основе биометрической проверки.",
      fees: "Тарифы",
      feesText1: "Ознакомьтесь с полным списком тарифов, найдите свой наиболее выгодный метод для пополнения и вывод средств. Мы всегда стараемся предложить самые выгодные курсы и самые низкие тарифы.",
      feesText2: "Тарифы на пополнение и вывод денежных средств и криптовалюты для частных лиц.",
      feesText3: "Особые предложения клиентам",
    }
  }
}
