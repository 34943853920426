import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { createI18n } from "vue-i18n";
import messages from "./i18n/messages";
import Vue3TouchEvents from "vue3-touch-events";


const i18n = createI18n({
  locale: 'en',
  messages
})

createApp(App)
  .use(router)
  .use(i18n)
  .use(Vue3TouchEvents)
  .mount('#app')
