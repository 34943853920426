import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue')
  },
  {
    path: '/refund_and_return',
    name: 'RefundAndReturn',
    component: () => import('../views/documents/RefundAndReturn.vue')
  },
  {
    path: '/user_agreement',
    name: 'UserAgreement',
    component: ()=> import('../views/documents/UserAgreement.vue')
  },
  {
    path: '/aml_and_ctf',
    name: 'AMLAndCTF',
    component: ()=> import('../views/documents/AMLAndCTF.vue')
  },
  {
    path: '/gdrp_and_privacy',
    name: 'GDRPAndPrivacy',
    component: ()=> import('../views/documents/GDRPAndPrivacy.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
